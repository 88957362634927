import { store } from '@/store/store';
import { NotificationDto } from '@/components/header/Notification/notification-dto';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'NotificationModule',
    store,
    dynamic: true
})
class NotificationModule extends VuexModule {
    public unReadNotifications: NotificationDto[] = [];

    @Action({ commit: 'onGetAvisos' })
    public async getAvisos() {
        return await ssmHttpService.get(API.avisos, null, false);
    }

    @Action
    public async modificarAviso(aviso: NotificationDto) {
        await ssmHttpService.put(API.avisos + '/' + aviso.id, aviso);
    }

    @Action
    public async NotificacionVista(aviso: NotificationDto) {
        await ssmHttpService.put(API.avisos + '/NotificacionVista', aviso,false);
        this.getAvisos();
    }

    @Action
    public async NotificacionCancelarCitaModificada(aviso: NotificationDto) {
        await ssmHttpService.put(API.avisos + '/NotificacionCancelarCitaModificada', aviso,false);
        this.getAvisos();
    }

    @Mutation
    public onGetAvisos(res: NotificationDto[]) {
        this.unReadNotifications = res;
    }

}
export default getModule(NotificationModule);