import { UtilsString } from '@/utils/utils-string';
import { MenuItem } from './menu-item';
import { Permission } from './permissions';

// Cada opción tendrá indicado:
// - el "i18n" con la key para obtener el texto traducido a mostrar
// - ó el "text" con el texto a mostrar (si no se quiere usar multiidioma)
export const MenuOptions: MenuItem[] = [
    {
        icon: 'home',
        text: 'Inicio',
        i18n: 'menu.inicio',
        link: '/main/home',
        permission: UtilsString.Empty,
        children: [],
    },
    {
        icon: 'person',
        text: 'pacientes',
        i18n: 'menu.pacientes',
        link: '/main/pacientes/lista',
        permission: UtilsString.Empty,
        children: [],
    }, {
        icon: 'date_range',
        text: 'calendario',
        i18n: 'menu.calendario',
        link: '/main/calendario',
        permission: UtilsString.Empty,
        children: [],
    }, {
        icon: 'dashboard',
        text: 'Seguimiento',
        i18n: '',
        link: '/main/dashboard/empresarial',
        permission: UtilsString.Empty,
        children: [],
    }, {
        icon: 'note',
        text: 'Plantillas',
        i18n: '',
        link: '/main/plantillas/lista',
        permission: UtilsString.Empty,
        children: [],
    }, {
        icon: 'wb_cloudy',
        text: 'Base de datos',
        i18n: '',
        link: '',
        permission: UtilsString.Empty,
        children: [{
            icon: 'blur_circular',
            text: 'patologias',
            i18n: 'menu.patologias',
            link: '/main/bd/patologias/lista',
            permission: UtilsString.Empty,
            children: [],
        }, {
            icon: 'filter_center_focus',
            text: 'Objetivos tratamiento',
            i18n: '',
            link: '/main/bd/objetivos/lista',
            permission: UtilsString.Empty,
            children: [],
        }, {
            icon: 'filter_tilt_shift',
            text: 'Estados fisiológicos',
            i18n: '',
            link: '/main/bd/estados_fisiologicos/lista',
            permission: UtilsString.Empty,
            children: [],
        }, {
            icon: 'local_dining',
            text: 'Estilos de alimentación',
            i18n: '',
            link: '/main/bd/estilos_de_alimentacion/lista',
            permission: UtilsString.Empty,
            children: [],
        }, {
            icon: 'directions_run',
            text: 'Tipos de deporte',
            i18n: '',
            link: '/main/bd/tipos_de_deporte/lista',
            permission: UtilsString.Empty,
            children: [],
        }, {
            icon: 'restaurant',
            text: 'Platos',
            i18n: '',
            link: '/main/bd/platos/lista',
            permission: UtilsString.Empty,
            children: [],
        }, {
            icon: 'kitchen',
            text: 'Alimentos',
            i18n: '',
            link: '/main/bd/alimento/lista',
            permission: UtilsString.Empty,
            children: [],
        }, {
            icon: 'local_cafe',
            text: 'Suplementos',
            i18n: '',
            link: '/main/bd/suplemento/lista',
            permission: UtilsString.Empty,
            children: [],
        }],
    }
    , {
        icon: 'settings',
        text: 'calendario',
        i18n: 'menu.settings',
        link: '/main/configuracion/ficha',
        permission: UtilsString.Empty,
        children: [],
    }, {
        icon: 'send',
        text: 'Sugerencias',
        i18n: '',
        link: '/main/sugerencias',
        permission: UtilsString.Empty,
        children: [],
    }, {
        icon: 'keyboard_backspace',
        text: 'Cerrar sesión',
        i18n: '',
        link: '/main/cerrar_sesion',
        permission: UtilsString.Empty,
        children: [],
    }, {
        icon: 'fingerprint',
        text: 'Admin',
        i18n: '',
        link: '/main/admin',
        permission: Permission.readAdmin,
        children: [{
            icon: 'exit_to_app',
            text: 'Accesos app',
            i18n: '',
            link: '/main/admin/accesos/lista',
            permission: Permission.readAdmin,
            children: [],
        }, {
            icon: 'person_pin_circle',
            text: 'Nutricionistas',
            i18n: '',
            link: '/main/admin/nutricionistas/lista',
            permission: Permission.readAdmin,
            children: [],
        }, {
            icon: 'adjust',
            text: 'Odoo',
            i18n: '',
            link: '/main/admin/odoo/operations',
            permission: Permission.readAdmin,
            children: [],
        }, {
            icon: 'local_phone',
            text: 'LLamadas',
            i18n: '',
            link: '/main/admin/llamadas/lista',
            permission: Permission.readAdmin,
            children: [],
        }, {
            icon: 'group_add',
            text: 'Nuevos registros',
            i18n: '',
            link: '/main/admin/registros_web/nuevos',
            permission: Permission.readAdmin,
            children: [],
        }, {
            icon: 'open_in_new',
            text: 'Alimentos',
            i18n: '',
            link: '/main/admin/alimentos/lista',
            permission: Permission.readAdmin,
            children: [],
        }, {
            icon: 'open_in_new',
            text: 'Platos',
            i18n: '',
            link: '/main/admin/platos/lista',
            permission: Permission.readAdmin,
            children: [],
        }
    ],
    }

];
