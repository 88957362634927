

























































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import LayoutMenuModule from "@/store/modules/layout-menu-module";
import { MenuItem } from "../Menu/menu-item";
import { MenuOptions } from "@/components/Menu/menu-options";
import { OptionsBuscardor } from "@/components/header/Opbuscardor";
import { UtilsString } from "../../utils/utils-string";
import { KeyboardKeyCodes } from "../../utils/keyboardKeyCodes";
@Component({
  components: {
    UserProfile: () =>
      import("@/components/header/UserProfile/UserProfile.vue"),
    Notification: () =>
      import("@/components/header/Notification/notification.vue")
  }
})
export default class Header extends Vue {
  @Prop() public title!: string;
  public searchText: string = "";
  public searchitems: MenuItem[] = [];
  public maxsearch: number = 12;
  public menu = true;
  public contador: number = 0;

  public tecla(event: any) {
    if (event.keyCode === KeyboardKeyCodes.arrow_down) {
      if (this.contador < this.searchitems.length - 1) {
        this.contador++;
      }
      // this.$vuetify.breakpoint.mdAndDown
    } else if (event.keyCode === KeyboardKeyCodes.arrow_up) {
      if (this.contador > 0) {
        this.contador--;
      }
    } else if (event.keyCode === KeyboardKeyCodes.enter) {
      this.irA(this.searchitems[this.contador]);
    } else if (event.keyCode === KeyboardKeyCodes.escape) {
      this.contador = 0;
      this.searchitems = [];
      this.searchText = "";
      this.menu = false;
    }
  }

  @Watch("searchText")
  public textChange() {
    this.searchitems = [];
    this.contador = 0;
    this.searchin(MenuOptions);
    this.searchin(OptionsBuscardor);
  }
  public async searchin(Options: MenuItem[]) {
    const i18n = await import("@/i18n/i18n");

    if (this.searchitems.length >= this.maxsearch) {
      return;
    }
    for (let entry of Options) {
      if (
        UtilsString.eliminarDiacriticosEs(i18n.default.tc(entry.i18n))
          .toLowerCase()
          .includes(
            UtilsString.eliminarDiacriticosEs(this.searchText).toLowerCase()
          )
      ) {
        this.searchitems.push(entry);
      }
      this.searchItem(entry, this.searchText, i18n.default);
    }
    try {
      this.searchitems.splice(this.maxsearch, this.searchitems.length);
    } catch (error) {}

    if (this.searchitems.length !== 0 && this.searchText.trim() !== "") {
      this.menu = true;
    } else {
      this.menu = false;
    }
  }
  public searchItem(Item: MenuItem, Text: string, i18n: any) {
    if (this.searchitems.length < this.maxsearch) {
      for (let entry of Item.children) {
        if (
          UtilsString.eliminarDiacriticosEs(i18n.tc(entry.i18n))
            .toLowerCase()
            .includes(
              UtilsString.eliminarDiacriticosEs(this.searchText).toLowerCase()
            )
        ) {
          if (this.searchitems.length >= this.maxsearch) {
            break;
          }

          this.searchitems.push(entry);
        }
        this.searchItem(entry, this.searchText, i18n);
      }
    }
  }
  public get drawer(): boolean {
    return LayoutMenuModule.visible;
  }
  public set drawer(newtoggle: boolean) {
    LayoutMenuModule.setVisible(newtoggle);
  }
  public irA(Item: MenuItem) {
    this.contador = 0;
    this.searchitems = [];
    this.searchText = "";
    this.$router.push(Item.link);
  }
}
