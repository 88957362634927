import { UtilsString } from '@/utils/utils-string';
import { MenuItem } from '@/components/Menu/menu-item';

// Cada opción tendrá indicado:
// - el "i18n" con la key para obtener el texto traducido a mostrar
// - ó el "text" con el texto a mostrar (si no se quiere usar multiidioma)
export const OptionsBuscardor: MenuItem[] = [
    {
        icon: 'person',
        text: UtilsString.Empty,
        i18n: 'menu.crear_paciente',
        link: '/main/pacientes/edit/',
        permission: UtilsString.Empty,
        children: [],
    }, {
        icon: 'person',
        text: UtilsString.Empty,
        i18n: 'menu.abrir_ficha',
        link: '/main/pacientes/lista',
        permission: UtilsString.Empty,
        children: [],
    }, {
        icon: 'date_range',
        text: UtilsString.Empty,
        i18n: 'menu.crear_cita',
        link: '/main/calendario',
        permission: UtilsString.Empty,
        children: [],
    }, {
        icon: 'date_range',
        text: UtilsString.Empty,
        i18n: 'menu.abrir_cita',
        link: '/main/calendario',
        permission: UtilsString.Empty,
        children: [],
    },

]