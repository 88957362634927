//
// Constantes de permisos disponibles
//

export class Permission {
    public static readUsuarios = 'read_usuarios';
    public static readRoles = 'read_roles';
    public static readPartes = 'read_partes';
    public static readAdmin = 'read_admin';
}
